import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const APosPage = () => (
    <Layout>
        <Seo 
            title="Wordpress to Webflow case study" 
            description="Case study of the Wordpress to Webflow web design and development project completed by Launch Lab for APositive."
        />

        <Hero
            h1="Wordpress to Webflow case study"  
            h2="Design &amp; Development of the new APositive website"
        />

        
        <section className="bg-noise apos section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/apositive.png"
                        alt="Screens from the APositive website"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>

        </section>
        
        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#testimonial')} className="btn">
                                        Testimonial
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>APositive is an Australian financial technology startup, with offices in Melbourne, Sydney, New Zealand and the Philippines. To support a brand facelift a newly designed website was required.</p>
                                    <p>The old website was built on Wordpress and the design required a complete overhaul.</p>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h3>The work</h3>
                                    <p>Working closely with APositive's head of marketing, who provided guidance on the website structure and content requirements, Launch Lab designed the new website.</p>
                                    <p>We then built the website, with content management system (CMS), on the popular Webflow platform.</p>
                                    <p>Part of the requirement was to export certain content from Wordpress and import it into Webflow. While Webflow provides a helpful way of importing content from Wordpress some manual intervention was required to improve existing blog post content and images. However the overall migration process was seamless.</p>
                                    <p>Launch Lab had previously worked with the APositive head of marketing at another company to build their website. That particular website was developed using Gatsby and a headless CMS called Contentful.</p>
                                    <p>The decision to use Webflow instead of a headless CMS solution was based on:</p>
                                    <ul>
                                        <li>Lower development costs</li>
                                        <li>Faster development timeline</li>
                                        <li>Great CMS experience</li>
                                        <li>Rapid publishing (deployment) to staging and / or production</li>
                                    </ul>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/web-design/" className="btn btn-lg btn-line">Web design</Link>
                                    <Link to="/webflow-developer/" className="btn btn-lg btn-line">Webflow development</Link>
                                </div>
                                <div id="testimonial" className="porti-text-section">
                                    <h4>Testimonial</h4>
                                    <Testimonial
                                        quote="I've just completed a project with Launch Lab and it's the second time I've worked with them on launching a website (for two different companies, APositive and Waddle). I can't praise them highly enough. When I got the opportunity to build a new website, my first thought was 'speak to Shaun'. They know exactly what they are doing, I trust their recommendations completely and their work is of the highest quality. From beginning to end everything has gone smoothy and professionally. Highly recommend!."
                                        name="Russ Watts"
                                        title="Head of Marketing, APositive"
                                    />
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the APositive website.</p>
                                    <a href="https://www.apositive.com.au" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            title="Start a project"
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default APosPage